import { TextField } from "react-admin";
import { JsonField } from "../../ra-lb-tools/components/jsonField";
import { MyList } from "../../ra-lb-tools/components/myList";
import { MyReferencesField } from "../../ra-lb-tools/components/myReference";
import { MonetizationOnOutlined } from "@mui/icons-material";

export const PriceIcon = MonetizationOnOutlined;

export const ProductPriceList = (props) => {
  return <PriceList filter={{ productId: props.productId }} />;
};

export const PriceList = (props) => {
  return (
    <MyList {...props} dataGridProps={{ bulkActionButtons: false }}>
      <TextField source="id" />
      <TextField source="nickname" />
      <TextField source="currency" />
      <TextField source="billing_scheme" />
      <TextField source="product" label={"Product id"} />
      <MyReferencesField
        reference="products"
        source="product"
        target="processorId"
      />
      <TextField source="tiers" />
      <TextField source="tiers_mode" />
      <TextField source="unit_amount" />
      <JsonField source="recurring" />
    </MyList>
  );
};

PriceList.defaultProps = {
  title: "Prices",
  resource: "prices",
  filter: {},
};
