import {
  AnalyticsOutlined,
  ForumOutlined,
  HelpOutlineOutlined,
  Launch,
  PublishedWithChanges,
  ShoppingCartOutlined,
  ViewComfyOutlined,
} from "@mui/icons-material";
import { MyMenu } from "../ra-lb-tools/components/myMenu";
import { Box } from "@mui/material";
import { MenuItem } from "../ra-lb-tools/components/MenuItem";
import { AccordionMenu } from "../ra-lb-tools/components/accordionMenu";

const externalSectionData = [
  {
    to: "https://docs.harborlockers.com/",
    leftIcon: <ViewComfyOutlined />,
    primaryText: "Documentation",
  },
  {
    to: "https://store.harborlockers.com/",
    leftIcon: <ShoppingCartOutlined />,
    primaryText: "Store",
  },
  {
    to: "https://harborlockers.com/changelog/",
    leftIcon: <PublishedWithChanges />,
    primaryText: "Changelogs",
  },
  {
    to: "https://community.harborlockers.com/",
    leftIcon: <ForumOutlined />,
    primaryText: "Forums",
  },

  {
    to: "https://harborlockers.com/submit-a-ticket/",
    leftIcon: <HelpOutlineOutlined />,
    primaryText: "Support Ticket",
  },
  {
    to: "https://status.harborlockers.com/",
    leftIcon: <AnalyticsOutlined />,
    primaryText: "Platform Status",
  },
];

const ExternalSection = () => {
  return (
    <AccordionMenu title={"External links"} icon={Launch}>
      <Box>
        {externalSectionData.map((data, idx) => (
          <MenuItem
            key={idx}
            {...data}
            RightIcon={<Launch />}
            target="_blank"
          />
        ))}
      </Box>
    </AccordionMenu>
  );
};

export const Menu = () => {
  return (
    <MyMenu>
      <ExternalSection />
    </MyMenu>
  );
};
