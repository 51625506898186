import {
  TextField,
  TextInput,
  NumberInput,
  SimpleFormIterator,
  ArrayInput,
  SelectInput,
  TabbedShowLayout,
} from "react-admin";
import BusinessIcon from "@mui/icons-material/Business";
import { JsonField } from "react-admin-json-view";
import { JsonInput } from "react-admin-json-view";
import { TitledShow } from "../ra-lb-tools/components/titledRecord";
import { FederationList } from "./federatios";
import { useParams } from "react-router-dom";
import { MyList } from "../ra-lb-tools/components/myList";
import {
  MyEdit as Edit,
  MyCreate as Create,
} from "../ra-lb-tools/components/myMutator";

export const BusinessViewIcon = BusinessIcon;

const typeOptions = [
  { id: "luxer", name: "Luxer" },
  { id: "test", name: "Test" },
];

export const LockerProviderList = () => (
  <MyList
    resource="locker-providers"
    dataGridProps={{ bulkActionButtons: false }}
  >
    <TextField source="id" />
    <TextField source="name" />
    <TextField source="type" />
    <JsonField
      source="settings"
      reactJsonOptions={{
        name: null,
        collapsed: true,
        enableClipboard: false,
        displayDataTypes: false,
        theme: "google",
      }}
    />
    <TextField source="webhookPath" />
  </MyList>
);

export const LockerProviderShow = () => {
  const id = parseInt(useParams()["id"]);

  return (
    <TitledShow>
      <TabbedShowLayout>
        <TabbedShowLayout.Tab label="Summary">
          <TextField source="id" />
          <TextField source="name" />
          <TextField source="type" />
          <TextField source="webhookPath" />
          <JsonField
            source="settings"
            reactJsonOptions={{
              name: "settings",
              collapsed: false,
              enableClipboard: false,
              displayDataTypes: false,
              theme: "google",
            }}
          />
        </TabbedShowLayout.Tab>
        <TabbedShowLayout.Tab label="Federations" path="federations">
          <FederationList filter={{ locker_provider_id: id }} />
        </TabbedShowLayout.Tab>
      </TabbedShowLayout>
    </TitledShow>
  );
};

export const LockerProviderEdit = () => (
  <Edit columns={1}>
    <TextInput source="id" disabled />
    <TextInput source="name" label="Name" />
    <TextInput source="type" label="Type" />
    <JsonInput
      source="settings"
      jsonString={false} // Set to true if the value is a string, default: false
      reactJsonOptions={{
        name: null,
        collapsed: false,
        enableClipboard: false,
        displayDataTypes: false,
        theme: "google",
      }}
    />
  </Edit>
);

export const LockerProviderCreate = () => (
  <Create columns={1}>
    <TextInput source="name" label="Name" />
    <SelectInput source="type" choices={typeOptions} />
    <TextInput source="apiUrl" label="API url" />
    <TextInput source="sharedKey" label="Shared Key" />
    <ArrayInput source="lockersMap" label="Lockers Map">
      <SimpleFormIterator>
        <TextInput source="remoteType" label="Remote Type Name" />
        <NumberInput source="local_type_id" label="Local Type ID" />
      </SimpleFormIterator>
    </ArrayInput>
  </Create>
);
