import { AutocompleteInput, useChoicesContext } from "react-admin";
import { JsonStyled } from "./jsonStyled";
import { Debug } from "./debug";
import { Dot } from "./dot";
import { Box } from "@mui/material";

const myProps = ["slack"];

const pickBy = (obj, filter) => {
  return Object.fromEntries(Object.entries(obj).filter(filter));
};

const theirProps = (props) => {
  return pickBy(props, ([key]) => !myProps.includes(key));
};

const calculateWidth = (choices, label, slack) => {
  const length = Math.max(
    ...choices.map((choice) =>
      typeof label == "function" ? label(choice).length : choice[label].length
    )
  );

  return length + slack + "ch";
};

const AutocompleteInputFluid = (props) => {
  const { allChoices } = useChoicesContext();

  const inputSx = {
    width: calculateWidth(allChoices, props.optionText, props.slack),
    maxWidth: "50vw",
    "& .MuiAutocomplete-input": {
      textOverflow: "unset",
    },
    display: "inline-block",
    verticalAlign: "top",
    flex: 1,
  };

  const debugSx = {
    display: "inline-block",
    marginLeft: 10,
    maxHeight: "50vh",
    maxWidth: "50vw",
    overflowY: "auto",
    flexShrink: 1,
  };

  return (
    <Box className="AutocompleteInputFluid" sx={{ display: "flex" }}>
      <AutocompleteInput
        {...theirProps(props)}
        sx={inputSx}
        className="AutocompleteInput"
      >
        {props.children}
      </AutocompleteInput>
      <Debug sx={debugSx}>
        <Dot>
          <JsonStyled data={allChoices} />
        </Dot>
      </Debug>
    </Box>
  );
};

AutocompleteInputFluid.defaultProps = {
  slack: 4,
};

export default AutocompleteInputFluid;
