import {
  TextField,
  EmailField,
  TextInput,
  BooleanInput,
  PasswordInput,
  DateField,
  SelectInput,
  TabbedShowLayout,
  useTheme,
  useGetIdentity,
  useRecordContext,
} from "react-admin";
import { Link } from "react-router-dom";
import {
  validateUserCreation,
  validateUserUpdate,
} from "../utils/customValidators";
import { MyBooleanField as BooleanField } from "../components/myBooleanField";

import { TitledShow } from "../ra-lb-tools/components/titledRecord";
import { Stack } from "@mui/material";
import { useParams } from "react-router-dom";
import { TowerList } from "./tower/towers";
import { ReservationList } from "./reservations";
import { openLockerTokenList as OpenLockerTokenList } from "./openLockerTokens";
import get from "lodash/get";
import { Settings } from "../ra-lb-tools/utils/settings";
import { MyList } from "../ra-lb-tools/components/myList";
import { SystemList } from "./systems";
import { LocationList } from "./locations/locations";
import {
  MyEdit as Edit,
  MyCreate as Create,
} from "../ra-lb-tools/components/myMutator";
import { LockerUsageList } from "./lockerUsage";
import { sxSpot } from "../ra-lb-tools/theme/styles";
import { LabeledColumn } from "../ra-lb-tools/components/labeledColumn";
import {
  MyReferenceField,
  MyReferenceInput,
} from "../ra-lb-tools/components/myReference";
import { OrganizationList } from "./billing/organizations";
import { PersonOutline } from "@mui/icons-material";

export const UserIcon = PersonOutline;

const roles = {
  SUPERUSER: 1,
  SERVICE_PROVIDER: 2,
};

const roleChoices = [
  { id: roles.SERVICE_PROVIDER, name: "Service Provider" },
  { id: roles.SUPERUSER, name: "Superuser" },
];

const rowStyle = (record, palette, currentUserId) => {
  return {
    backgroundColor:
      record.id == currentUserId && Settings.debugging()
        ? palette.specialColors.highlight
        : palette.background.default,
  };
};

const userFilters = [
  <TextInput label="Email" source="email" alwaysOn key={1} />,
];

const TowersOwned = () => {
  const record = useRecordContext();
  if (!record) return null;
  if (!record.towersOwned) return null;

  const onClick = (e) => e.stopPropagation();
  const to = `/users/${record.id}/show/towers`;

  return (
    <Link to={to} onClick={onClick} style={sxSpot()}>
      <TextField source="towersOwned" />
    </Link>
  );
};

export const UserList = (props) => {
  const theme = useTheme();
  const palette = theme[0].palette;
  const currentUserId = get(useGetIdentity(), "data.id");

  return (
    <MyList
      {...props}
      resource="users"
      filters={userFilters}
      dataGridProps={{
        rowStyle: (record) => rowStyle(record, palette, currentUserId),
        omit: ["id"],
      }}
    >
      <TextField source="id" />
      <TextField source="fullName" />
      <EmailField source="email" />
      <BooleanField source="emailValidated" />
      <BooleanField source="isActive" />
      <BooleanField source="isSuperuser" />
      <TowersOwned label="Towers Owned" />
      <TextField source="status" />
      <DateField source="createdAt" />
    </MyList>
  );
};

export const UserEdit = (props) => {
  return (
    <Edit
      {...props}
      formProps={{
        mode: "onBlur",
        reValidateMode: "onBlur",
        validate: validateUserUpdate,
      }}
    >
      <TextInput source="id" disabled />
      <TextInput source="email" />
      <BooleanInput source="emailValidated" />
      <TextInput source="firstName" />
      <TextInput source="lastName" />
      <PasswordInput source="password" />
      <BooleanInput source="isActive" />
      <BooleanInput source="isSuperuser" />
    </Edit>
  );
};

export const UserCreate = (props) => {
  return (
    <Create
      {...props}
      formProps={{
        mode: "onBlur",
        reValidateMode: "onBlur",
        validate: (values) => validateUserCreation(values, roles),
      }}
    >
      <TextInput source="email" />
      <BooleanInput source="emailValidated" />
      <TextInput source="firstName" />
      <TextInput source="lastName" />
      <PasswordInput source="password" />
      <BooleanInput source="isActive" />
      <SelectInput
        source="role"
        choices={roleChoices}
        optionText="name"
        optionValue="id"
      />
    </Create>
  );
};

export const UserShow = () => {
  const id = useParams()["id"];

  return (
    <TitledShow>
      <TabbedShowLayout>
        <TabbedShowLayout.Tab label="Summary">
          <Stack spacing={4} direction="row">
            <LabeledColumn>
              <TextField source="id" />
              <TextField source="fullName" />
              <EmailField source="email" />
              <BooleanField source="emailValidated" />
              <BooleanField source="isActive" />
              <BooleanField source="isSuperuser" />
              <TextField source="status" />
              <DateField source="createdAt" />
            </LabeledColumn>
          </Stack>
        </TabbedShowLayout.Tab>
        <TabbedShowLayout.Tab label="Towers" path="towers">
          <TowerList filter={{ owner_id: id }} filters={null} />
        </TabbedShowLayout.Tab>
        <TabbedShowLayout.Tab label="Retired Towers" path="towers-retired">
          <TowerList filter={{ owner_id: id, status_id: 4 }} filters={null} />
        </TabbedShowLayout.Tab>
        <TabbedShowLayout.Tab label="Reservations" path="reservations">
          <ReservationList filter={{ user_id: id }} />
        </TabbedShowLayout.Tab>
        <TabbedShowLayout.Tab
          label="Open Locker Token"
          path="open-locker-tokens"
        >
          <OpenLockerTokenList filter={{ user_id: id }} />
        </TabbedShowLayout.Tab>
        <TabbedShowLayout.Tab label="Systems" path="systems">
          <SystemList filter={{ owner_id: id }} />
        </TabbedShowLayout.Tab>
        <TabbedShowLayout.Tab label="Locations" path="locations">
          <LocationList filter={{ owner_id: id }} />
        </TabbedShowLayout.Tab>
        <TabbedShowLayout.Tab label="Locker Usage" path="locker-usage">
          <LockerUsageList filter={{ user_id: id }} />
        </TabbedShowLayout.Tab>
        <TabbedShowLayout.Tab
          label="Owned Organizations"
          path="owned-organizations"
        >
          <OrganizationList filter={{ account_owner_id: id }} />
        </TabbedShowLayout.Tab>
        <TabbedShowLayout.Tab
          label="Serviced Organizations"
          path="serviced-organizations"
        >
          <OrganizationList filter={{ service_account_id: id }} />
        </TabbedShowLayout.Tab>
      </TabbedShowLayout>
    </TitledShow>
  );
};

export const UserInput = (props) => {
  return (
    <MyReferenceInput reference="users" primaryField={"email"} {...props} />
  );
};

export const UserField = (props) => {
  return (
    <MyReferenceField reference="users" primaryField={"email"} {...props} />
  );
};
