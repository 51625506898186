import {
  TextField,
  ReferenceField,
  TextInput,
  ReferenceInput,
  SelectInput,
  SelectField,
  BooleanInput,
  FilterList,
  FilterListItem,
  useTheme,
  useRecordContext,
  DateField,
} from "react-admin";
import DnsIcon from "@mui/icons-material/Dns";
import { statusChoices, accessModeChoices } from "./base";
import { StatusHeader } from "./components";
import AutocompleteInputFluid from "../../ra-lb-tools/components/autocompleteInputFluid";
import { Card, CardContent } from "@mui/material";
import { Link } from "react-router-dom";
import { LocationField, LocationInput } from "../locations/components";
import { MyList } from "../../ra-lb-tools/components/myList";
import { SystemField, SystemInput } from "../systems";
import {
  MyEdit as Edit,
  MyCreate as Create,
} from "../../ra-lb-tools/components/myMutator";
import { sxSpot } from "../../ra-lb-tools/theme/styles";
import { getFields } from "../../ra-lb-tools/utils/tools";
import { MyBooleanField as BooleanField } from "../../components/myBooleanField";
import {
  MyReferenceField,
  MyReferenceInput,
} from "../../ra-lb-tools/components/myReference";

export const TowerIcon = DnsIcon;

export const TowerFilterSidebar = () => (
  <Card sx={{ order: -1, mr: 2, width: 200, marginTop: "20px" }}>
    <CardContent>
      <FilterList label="Tower is for Test?">
        <FilterListItem label="Yes" value={{ is_for_test: true }} />
        <FilterListItem label="No" value={{ is_for_test: false }} />
      </FilterList>
    </CardContent>
  </Card>
);

export const isForTestChoices = [
  { id: true, name: "Yes" },
  { id: false, name: "No" },
];

const towerFilters = [
  <TextInput source="tower_id" alwaysOn key="tower_id" />,
  <TextInput source="name" alwaysOn key="name" />,
  <TextInput source="search" alwaysOn key="search" />,
  <SelectInput source="status_id" choices={statusChoices} alwaysOn key={1} />,
  <SelectInput
    source="has_oos"
    label="Is OOS"
    key={"has_oos"}
    choices={isForTestChoices}
    alwaysOn
  />,
  <SelectInput
    source="is_for_test"
    choices={isForTestChoices}
    key={5}
    alwaysOn
    defaultValue={false}
  />,
  <LocationInput source="location_id" allowEmpty alwaysOn key={2} />,
  <SystemInput
    source="systemId"
    label="System"
    allowEmpty
    alwaysOn
    key={3}
    slack={8}
  />,
  <ReferenceInput
    source="owner_id"
    label="Owner"
    reference="users"
    allowEmpty
    key={4}
  >
    <AutocompleteInputFluid
      label="Owner"
      filterToQuery={(searchText) => ({ email: `%${searchText}` })}
      optionText="email"
      optionValue="id"
    ></AutocompleteInputFluid>
  </ReferenceInput>,
];

const hasOOS = (record) => record.statusId == 2 || record.oosLockersCount;

const rowStyle = (record, palette) => {
  return {
    backgroundColor: record.isForTest
      ? palette.specialColors.testing
      : hasOOS(record)
      ? palette.specialColors.oos
      : palette.background.default,
  };
};

const OosLockers = () => {
  const record = useRecordContext();
  if (!record) return null;
  if (!record.oosLockersCount) return null;

  const onClick = (e) => e.stopPropagation();
  const to = `/towers/${record.id}/show`;

  return (
    <Link to={to} onClick={onClick} style={sxSpot()}>
      <TextField source="oosLockersCount" />
    </Link>
  );
};

export const TowerList = (props) => {
  const theme = useTheme();
  const palette = theme[0].palette;
  const resource = "towers";

  const userFields = (
    <>
      <TextField source="id" />
      <BooleanField source="isForTest" label="Is for test?" />
      <TextField source="name" />
      <SystemField label="System" />
      <LocationField label="Location" />
      <TextField source="location.city" label="City" />
      <TextField source="location.state" label="State" />
      <SelectField source="accessModeId" choices={accessModeChoices} />
      <SelectField
        source="statusId"
        choices={statusChoices}
        label={<StatusHeader />}
      />
      <OosLockers label="OOS Lockers" />
      <ReferenceField source="ownerId" reference="users" link="show">
        <TextField source="email" />
      </ReferenceField>
      <ReferenceField
        source="towerModelId"
        reference="tower-models"
        link="show"
      >
        <TextField source="name" />
      </ReferenceField>
      <TextField source="fwVersion" />
      <BooleanField source="hasKeypad" />
    </>
  );

  const debugFields = (
    <>
      <DateField
        source="lastEventsSync"
        showTime={true}
        label="Last Events Sync"
      />
    </>
  );

  return (
    <MyList
      {...props}
      filters={towerFilters}
      resource={resource}
      dataGridProps={{
        rowStyle: (record) => rowStyle(record, palette),
        bulkActionButtons: false,
      }}
      filterDefaultValues={{
        is_for_test: false,
      }}
    >
      {getFields(userFields, debugFields)}
    </MyList>
  );
};

export const TowerEdit = (props) => {
  return (
    <Edit {...props}>
      <TextInput source="id" disabled />
      <TextInput source="name" />
      <TextInput source="pinCode" />
      <TextInput source="description" multiline />
      <SystemInput label="System" />
      <LocationInput />
      <SelectInput
        source="statusId"
        choices={statusChoices}
        optionValue="id"
        optionText="name"
        label={<StatusHeader />}
      />
      <SelectInput
        source="accessModeId"
        choices={accessModeChoices}
        optionValue="id"
        optionText="name"
      />
      <ReferenceInput
        label="Owner"
        source="ownerId"
        reference="users"
        allowEmpty
      >
        <AutocompleteInputFluid
          filterToQuery={(searchText) => ({ email: `%${searchText}` })}
          optionText="email"
          optionValue="id"
        ></AutocompleteInputFluid>
      </ReferenceInput>
      <BooleanInput source="isForTest" />
      <BooleanInput source="hasKeypad" />
    </Edit>
  );
};

export const TowerCreate = (props) => {
  return (
    <Create {...props}>
      <TextInput source="name" />
      <TextInput source="pinCode" />
      <TextInput source="description" multiline />
      <SystemInput source="systemId" />
      <LocationInput />
      <ReferenceInput source="towerModelId" reference="tower-models">
        <AutocompleteInputFluid
          filterToQuery={(searchText) => ({ name: `%${searchText}` })}
          optionText="name"
          optionValue="id"
        ></AutocompleteInputFluid>
      </ReferenceInput>
      <SelectInput
        source="accessModeId"
        choices={accessModeChoices}
        optionValue="id"
        optionText="name"
      />
      <ReferenceInput source="ownerId" reference="users" allowEmpty>
        <AutocompleteInputFluid
          filterToQuery={(searchText) => ({ email: `%${searchText}` })}
          optionText="email"
          optionValue="id"
        ></AutocompleteInputFluid>
      </ReferenceInput>
      <SelectInput
        source="statusId"
        choices={statusChoices}
        optionValue="id"
        optionText="name"
        label={<StatusHeader />}
      />
      <BooleanInput source="isForTest" />
      <BooleanInput source="hasKeypad" />
    </Create>
  );
};

export const TowerInput = (props) => {
  return <MyReferenceInput source="towerId" reference="towers" {...props} />;
};

export const TowerField = (props) => {
  return <MyReferenceField source="towerId" reference="towers" {...props} />;
};
