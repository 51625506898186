import { Box } from "@mui/material";
import { useState } from "react";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { MenuItem } from "./MenuItem";

export const AccordionMenu = (props) => {
  const [open, setOpen] = useState(props.open);

  const MenuIcon = props.icon;
  const OpenIcon = open ? ExpandLessIcon : ExpandMoreIcon;

  const LeftIcon = MenuIcon || OpenIcon;
  const RightIcon = MenuIcon ? <OpenIcon /> : null;

  return (
    <Box className={"AccordionMenu"}>
      <MenuItem
        to={window.location.href.split("#").pop()}
        onClick={() => setOpen(!open)}
        primaryText={props.title}
        leftIcon={<LeftIcon />}
        className="AccordionMenuSwitch"
        RightIcon={RightIcon}
      />
      {open && (
        <Box className={"AccordionMenuItems"} sx={{ padding: "0 0 0 16px" }}>
          {props.children}
        </Box>
      )}
    </Box>
  );
};

AccordionMenu.defaultValues = {
  open: true,
};
