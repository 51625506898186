import React from "react";
import { DatagridRow } from "react-admin";

const MyRow = ({
  id,
  record,
  resource,
  basePath,
  children,
  highlightList,
  highlight_key,
  setRowRef,
  onMouseEnter,
  onMouseLeave,
  ...rest
}) => {
  const style = {
    border:
      "2px " +
      (highlightList.includes(record[highlight_key])
        ? "double black"
        : "solid transparent"),
  };

  return (
    <DatagridRow
      id={id}
      record={record}
      resource={resource}
      basePath={basePath}
      ref={(refElem) => setRowRef && setRowRef(record, refElem)}
      onMouseEnter={() => onMouseEnter && onMouseEnter(record)}
      onMouseLeave={() => onMouseLeave && onMouseLeave(record)}
      sx={style}
      {...rest}
    >
      {React.Children.map(children, (field) =>
        React.cloneElement(field, {
          record,
          resource,
          basePath,
        })
      )}
    </DatagridRow>
  );
};

MyRow.defaultProps = {
  highlightList: [],
};

export default MyRow;
