import { useResourceDefinitions } from "ra-core";
import { ResourceMenuItem } from "react-admin";
import get from "lodash/get";
import set from "lodash/set";
import pickBy from "lodash/pickBy";
import sortBy from "lodash/sortBy";
import { Box } from "@mui/material";
import { Settings } from "./settings";
import { customRouteMenu, debugInfo } from "../theme/styles";
import { AccordionMenu } from "../components/accordionMenu";
import { useCustomRoutes } from "../config/customRoutes";
import { getConfig } from "../config/packageSetup";
import { MenuItem } from "../components/MenuItem";

const isAvailableForMenu = (resource) =>
  (resource.hasList || resource.onMenu) &&
  (Settings.debugging() || !get(resource, "options.debugOnly"));

const getSx = (options) =>
  Object.assign(
    {},
    customRouteMenu,
    options.sx || {},
    options.debugOnly ? debugInfo : {}
  );

const renderResourceMenu = (resourceName, options) => (
  <Box key={resourceName} sx={getSx(options)} sortOrder={options.sortOrder}>
    <ResourceMenuItem name={resourceName} />
  </Box>
);

const renderCustomMenu = (route) => (
  <MenuItem
    key={route.path}
    to={route.path}
    primaryText={route.label || route.path.replaceAll(/[^\w\d\s]/g, " ").trim()}
    {...route.menuProps}
    sx={getSx(route.options || {})}
    sortOrder={route.options.sortOrder}
  />
);

const getGrouped = () => {
  const keys = getConfig().menuGroups || [];

  keys.includes("") || keys.unshift("");

  const grouped = keys.reduce((acc, key) => {
    if (typeof key == "string") {
      acc[key] = {
        label: key,
        items: [],
      };
    } else {
      acc[key.label] = { ...key, items: [] };
    }

    return acc;
  }, {});

  return grouped;
};

/**
 * Based on
 * https://github.com/marmelab/react-admin/blob/0e4c7ca1187649bbe63fd04321c20b4de0ee488b/packages/ra-ui-materialui/src/layout/ResourceMenuItems.tsx
 *
 * Edit: there is almost nothing left from the original code
 */
export const ResourceMenuItems = () => {
  const resources = Object.assign(
    {},
    useResourceDefinitions(),
    useCustomRoutes()
  );
  let menus = [];

  const available = pickBy(resources, isAvailableForMenu);
  const sorted = sortBy(available, (resource) =>
    get(resource, "options.sortOrder", 0)
  );

  const _ungrouped = pickBy(
    sorted,
    (resource) => !get(resource, "options.group")
  );
  Object.keys(_ungrouped).forEach((key, idx) => {
    const resource = _ungrouped[key];
    set(
      resource,
      "options.sortOrder",
      get(resource, "options.sortOrder", idx * 10)
    );
    const menu = resource.hasList
      ? renderResourceMenu(resource.name, resource.options)
      : renderCustomMenu(resource);

    menus.push(menu);
  });

  const _grouped = pickBy(sorted, (resource) => get(resource, "options.group"));
  const grouped = getGrouped();
  Object.keys(_grouped).forEach((key) => {
    const resource = _grouped[key];
    const menu = resource.hasList
      ? renderResourceMenu(resource.name, resource.options)
      : renderCustomMenu(resource);

    const group = resource.options.group;

    grouped[group] = grouped[group] || { label: group, items: [] };
    grouped[group].items.push(menu);
  });

  Object.keys(grouped).forEach((groupName) => {
    const group = grouped[groupName];
    if (!group.items.length) {
      return;
    }

    menus.push(
      <AccordionMenu
        key={groupName}
        title={groupName}
        sortOrder={group.sortOrder}
        icon={group.icon}
      >
        <Box>{group.items}</Box>
      </AccordionMenu>
    );
  });

  menus = sortBy(menus, (menu_entry) =>
    get(menu_entry, "props.sortOrder", 1000)
  );

  return <>{menus}</>;
};
