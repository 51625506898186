import { Box } from "@mui/material";
import { Menu } from "react-admin";
import capitalize from "lodash/capitalize";

export const MenuItem = ({ RightIcon, primaryText, ...rest }) => {
  const MenuText = (
    <Box className="MenuText" sx={{ display: "flex", flexGrow: 1 }}>
      <span style={{ marginRight: "auto" }}>{capitalize(primaryText)}</span>
      {RightIcon}
    </Box>
  );

  return <Menu.Item primaryText={MenuText} {...rest} />;
};
